const Switch = ({ isOn, onClick }) => {
  return (
    <span className={`mic_permission_switch ${!isOn ? 'blink-border' : ''}`}>
      <span className={`circle ${isOn ? 'on' : ''}`}>
        <input type="checkbox" checked={isOn} onChange={onClick} />
        <span className="thumb"></span>
      </span>
      <span className="track"></span>
    </span>
  );
};

export default Switch;
